import React from 'react';

export default function ZeroPrice() {
    return (
        <div>
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="mb-3">
                        <h1 className="h3 d-inline align-middle"> <strong></strong> </h1>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="">
                                <div className="">
                                    <h5 className="card-title mb-0"></h5>
                                    <br />
                                    <div className="shadow-lg p-4">
                                        <h2 className="fw-semibold">Freemium</h2>
                                        <p className="text-muted mb-2">Free</p>

                                        <div className="d-flex align-items-baseline">
                                            <span className="display-6 fw-bold">$0</span>
                                            <span className="text-muted ms-1">per month</span>
                                        </div>

                                        <button className="btn btn-primary w-100 mt-3" disabled>Activated Package</button>

                                        <div className="mt-4">
                                            <p className="fw-semibold">This includes:</p>
                                            <ul className="list-unstyled">
                                                <li className="d-flex align-items-center"><span>✅</span> Scrape up to 100 web pages</li>
                                                <li className="d-flex align-items-center"><span>✅</span> Support for 10 TXT, PDF, DOCX Files (1 MB per file)</li>
                                                <li className="d-flex align-items-center"><span>✅</span> Support for 5 image Files (1 MB per file)</li>
                                                <li className="d-flex align-items-center"><span>✅</span> Up to 5 million input and 0.5 million output tokens</li>
                                                <li className="d-flex align-items-center"><span>✅</span> Google Gemini 2.0 / Google Gemini 1.5</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}