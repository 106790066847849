import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import backendURL from "../config";

export default function VerifiedEmail() {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const { token } = useParams();

    useEffect(() => {
        document.title = "Email Verification | KlonIT Web Application";
        // console.log("Token:", token); // Debug: Log the token value

        const verifyEmail = async () => {
            try {
                // console.log("Before API call"); // Debug: Check if API call is reached
                const response = await axios.get(`${backendURL}/verify/${token}`);
                // console.log("API Response:", response); // Debug: Log API response

                const { access_token, user, message } = response.data;
                // console.log("Message from API: ", message)

                localStorage.setItem("access_token", access_token);
                localStorage.setItem("user", JSON.stringify(user));

                setSuccess(true);
                setMessage(message);

                // window.location.replace("/SignUp-Process-Steps");

            } catch (error) {
                console.error("API Error:", error); // Debug: Log the full error
                if (error.response) {
                    setError(
                        error.response.data.message ||
                        "Invalid or expired verification link."
                    );
                } else {
                    setError("Error verifying email.");
                }
            } finally {
                setLoading(false);
            }
        };

        if (token) {
          verifyEmail();
        } else {
            setError("Verification token is missing.");
            setLoading(false);
        }
    }, [token]);

    const handleProceedNext = () => {
        navigate("/SignUp-Process-Steps");
    };

    const handleResendEmail = async () => {
        // ... (Your existing handleResendEmail function) ...
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <main className="d-flex w-100">
                <div className="container d-flex flex-column">
                    <div className="row vh-100">
                        <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">
                                <div className="text-center mt-4">
                                    <h1 className="h2">Email Verified</h1>
                                    <p className="lead">
                                        {error
                                            ? error
                                            : success
                                                ? message
                                                : "Verifying your email..."}
                                    </p>
                                </div>

                                {success && (
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-grid gap-2 mb-3">
                                                <button
                                                    className="btn btn-lg btn-primary"
                                                    onClick={handleProceedNext}
                                                >
                                                    Click to Continue
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {error && (
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="m-sm-3">
                                                <div id="getError" className="alert alert-warning">
                                                    {error}
                                                </div>
                                                <div className="d-grid gap-2 mb-3">
                                                    <button
                                                        className="btn btn-lg btn-secondary"
                                                        onClick={handleResendEmail}
                                                    >
                                                        Resend Verification Email
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}