import React, { useState, useEffect } from 'react';
import backendURL from '../../config'; // Make sure this points to your backend

export default function ResetPassword() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Add a loading state

    useEffect(() => {
        document.title = 'Reset Password | KlonIT Web Application';
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage('');
        setError('');
        setLoading(true); // Set loading to true when the request starts

        if (newPassword !== verifyPassword) {
            setError('New passwords do not match.');
            setLoading(false); // Set loading to false if validation fails
            return;
        }

        if (newPassword.length < 8) {
            setError('New password must be at least 8 characters long.');
            setLoading(false); // Set loading to false if validation fails
            return;
        }

        const url = `${backendURL}/reset-password`;  // Use template literals correctly
        console.log("Fetching URL:", url); // Debugging: Check the URL

        try {
            const token = localStorage.getItem('access_token');
            console.log("Token:", token);   // Debugging: Check the token

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Use the retrieved token
                },
                body: JSON.stringify({
                    currentPassword,
                    newPassword,
                    verifyPassword,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(data.message);
                setCurrentPassword('');
                setNewPassword('');
                setVerifyPassword('');
            } else {
                setError(data.message || 'An error occurred.');
            }
        } catch (err) {
            console.error("Network error:", err); // Log the actual error
            setError('Network error. Please try again.');
        } finally {
            setLoading(false); // Set loading to false in all cases (success, error, network error)
        }
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title mb-0">Password</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="inputPasswordCurrent">
                            Current password
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="inputPasswordCurrent"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required // Add required attribute for HTML5 validation
                        />
                        <small>
                            <a href="/forgot-password" target='_blank'>Forgot your password?</a>
                        </small>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="inputPasswordNew">
                            New password
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="inputPasswordNew"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required // Add required attribute
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="inputPasswordNew2">
                            Verify password
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="inputPasswordNew2"
                            value={verifyPassword}
                            onChange={(e) => setVerifyPassword(e.target.value)}
                            required // Add required attribute
                        />
                    </div>
                    {message && <div className="alert alert-success">{message}</div>}
                    {error && <div className="alert alert-danger">{error}</div>}
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? 'Saving...' : 'Save changes'} {/* Show loading indicator */}
                    </button>
                </form>
            </div>
        </div>
    );
}