import { Link } from 'react-router-dom';
import { Box, Typography, Button, Paper } from '@mui/material';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import backendURL from '../config';

export default function UpgradePlan() {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = 'Upgrade Plan | KlonIT Web Application';
    fetchDocuments();
  }, []);

  // Function to fetch documents from the API
  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${backendURL}/get-subscription`, {
        headers,
      });
      setDocuments(response.data);
      setLoading(false);
      console.log('Get subscriptions: ', response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Authentication error:', error.response.data.error);
        setError('Authentication error: ' + error.response.data.error);
      } else {
        console.error('Error fetching data:', error);
        setError('Error fetching data: ' + error);
      }
      setLoading(false);
    }
  };

  if (loading) {
    return <Typography>Loading subscription information...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  // Access the subscription name safely
  const subscriptionName = documents[0]?.subscription_name || 'Freemium';

  return (
    <Box sx={{ p: 2 }}>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          {subscriptionName}
        </Typography>
        <Typography variant="body2" gutterBottom>
          You are on the {subscriptionName} Plan. Consider upgrading.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          component={Link}
          to="/billing/pricing"
        >
          Upgrade
        </Button>
      </Paper>
    </Box>
  );
}