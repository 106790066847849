// PaymentSuccess.js
import React, { useEffect } from 'react';

const PaymentSuccess = () => {

    useEffect(() => {
      // Ideally, you'd fetch some confirmation data from your backend here
      // using a GET request, based on a session ID or similar.  For this
      // example, we'll just display a simple message.
      document.title = 'Payment Successful | KlonIT';

    }, []);

    return (
        <div className="container">
            <div className="row justify-content-center mt-5">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body text-center">
                            <h1 className="card-title">Payment Successful!</h1>
                            <p className="card-text">Thank you for your purchase. Your subscription is now active.</p>
                            {/* You could add more details here, fetched from the server */}
                            <a href="/" className="btn btn-primary">Go to Dashboard</a> {/*  Change "/" to your dashboard route */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess;