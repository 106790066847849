import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import backendURL from "../config";

export default function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Get the navigate function

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage("");
        setError("");
        setLoading(true);

        try {
            const response = await fetch(`${backendURL}/forgot-password`, { // New endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }), // Send only the email
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(data.message); // Success message (e.g., "Reset link sent...")
                // Optionally redirect to a "check your email" page.  This is better UX
                // navigate('/check-email');
            } else {
                setError(data.message || 'An error occurred.');
            }
        } catch (err) {
            setError('Network error. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <main className="d-flex w-100 h-100">
                <div className="container d-flex flex-column">
                    <div className="row vh-100">
                        <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">
                                <div className="text-center mt-4">
                                    <h1 className="h2">Reset password</h1>
                                    <p className="lead">
                                        Enter your email to reset your password.
                                    </p>
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <div className="m-sm-3">
                                            <form onSubmit={handleSubmit}> {/* Use onSubmit here */}
                                                <div className="mb-3">
                                                    <label className="form-label">Email</label>
                                                    <input
                                                        className="form-control form-control-lg"
                                                        type="email"
                                                        name="email"
                                                        placeholder="Enter your email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="d-grid gap-2 mt-3">
                                                    <button type="submit" className="btn btn-lg btn-primary" disabled={loading}>
                                                        {loading ? 'Sending...' : 'Reset password'}
                                                    </button>
                                                </div>
                                                {message && <div className="alert alert-success">{message}</div>}
                                                {error && <div className="alert alert-danger">{error}</div>}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-3">
                                    Don't have an account? <a href="/SignUp">Sign up</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}