import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import backendURL from "../../config";
import RetrainButton from "../Chatbot/RetrainButton"
import Modal from "react-modal";
import EditTrainedData from "./EditTrainedData";
import { Tooltip } from "@mui/material"; // Import Tooltip

export default function ListTrainedData({ refresh }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fetchedUrl = queryParams.get('fetched_url');

  useEffect(() => {
    document.title = 'Website Lists | KlonIT Web Application';
    fetchDocuments();
  }, [refresh]);

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [refreshingRows, setRefreshingRows] = useState({}); // Track refreshing rows

  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(`${backendURL}/get-trained-website-links`, { headers });

      const updatedDocuments = response.data
        .filter(doc => doc.is_scrape === true && doc.is_trained === true)
        .map(doc => ({
          ...doc,
          icon: refreshingRows[doc.id] ? 'fa-spinner fa-spin' : 'fa-refresh'
        }));

      setDocuments(updatedDocuments);
      setFilteredDocuments(updatedDocuments);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Authentication error:", error.response.data.error);
      } else {
        setError("Error fetching website:", error);
      }
    }
  };

  const handleView = (url) => {
    window.open(url, '_blank');
  };


  const columns = [
    {
      name: 'Page Name',
      selector: row => row.title_tag,
      sortable: true,
      maxWidth: '315px',
      style: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    {
      name: 'Page Content',
      selector: row => row.page_content,
      maxWidth: '315px',
      style: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    {
      name: 'Date',
      selector: row => row.created_date,
      maxWidth: '315px',
      style: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    {
      name: 'Action',
      cell: row => (
        <>
          <Tooltip title="View Website Link in new tab" arrow>
            <button className="viewIcon" onClick={() => handleView(row.website_link)}>
              <i className="fa fa-eye fa-2x"></i>
            </button>
          </Tooltip>
          <Tooltip title="Scrape new page data and get trained" arrow>
            <button
              className="RefreshIcon"
              onClick={() => handleRefresh(row)}
              disabled={refreshingRows[row.id]} // Disable while refreshing
            >
              <i className={`fa ${refreshingRows[row.id] ? 'fa-spinner fa-spin' : (row.icon || 'fa-refresh')} fa-2x`}></i>
            </button>
          </Tooltip>
          <Tooltip title="Edit data and get trained" arrow>
            <button className="EditIcon" onClick={() => handleEdit(row)}>
              <i className="fa fa-pencil fa-2x"></i>
            </button>
          </Tooltip>
          <Tooltip title="Delete data" arrow>
            <button className="deleteIcon" onClick={() => handleDelete(row.id)}>
              <i className="fa fa-trash-o fa-2x"></i>
            </button>   
          </Tooltip>       
        </>
      ),
        width: '200px', // add width in action column
    },
  ];

  const ExpandedRow = ({ data }) => {
    return (
      <div style={{ padding: '10px', lineHeight: '1.5' }}>
        <p><strong>Website Link:</strong> {data.website_link}</p>
        <p><strong>Title Tag:</strong> {data.title_tag}</p>
        <p><strong>Page Content:</strong> {data.page_content}</p>
        <p><strong>Summary:</strong> {data.summary}</p>
        <p><strong>H1 Heading:</strong> {data.h1_tags}</p>
        <p><strong>H2 Heading:</strong> {data.h2_tags}</p>
        <p><strong>H3 Heading:</strong> {data.h3_tags}</p>
      </div>
    );
  };

  const handleDelete = async (documentId) => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      await axios.delete(`${backendURL}/delete-website-scrape-data/${documentId}`, { headers });
      setDocuments(documents.filter(doc => doc.id !== documentId));
      setFilteredDocuments(filteredDocuments.filter((doc) => doc.id !== documentId));
      setError("Website link has been deleted successfully.");
    } catch (error) {
      setError("Error deleting Website:", error.message);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = documents.filter((doc) =>
      doc.website_link.toLowerCase().includes(value) ||
      doc.title_tag.toLowerCase().includes(value) ||
      doc.page_content.toLowerCase().includes(value) ||
      (doc.h1_tags && doc.h1_tags.toLowerCase().includes(value)) ||
      (doc.h2_tags && doc.h2_tags.toLowerCase().includes(value)) ||
      (doc.h3_tags && doc.h3_tags.toLowerCase().includes(value))
    );

    setFilteredDocuments(filteredData);
  };

  const handleEdit = (document) => {
    setCurrentDocument(document);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentDocument(null);
    fetchDocuments();
  };

  const EditModalStyle = {
    content: {
      width: "50%",
      height: "50%",
      margin: "auto",
      padding: "20px",
      borderRadius: "10px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const handleRefresh = async (document) => {
    setRefreshingRows(prev => ({ ...prev, [document.id]: true })); // Start refreshing

    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      const websiteLinks_id = [document.id];
      const websiteLinks = [document.website_link];

      await axios.post(`${backendURL}/refresh-website-data`, { data: [websiteLinks_id, websiteLinks] }, { headers });

      // Display success message (Optional)
      setError("Website data refreshed and trained successfully.");
    } catch (error) {
      setError("Error refreshing website data:", error.message);
    } finally {
      // Ensure refreshing state is reset even on error
      setRefreshingRows(prev => ({ ...prev, [document.id]: false }));
      // Refresh data after the process (successful or not)
      fetchDocuments();
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
        <div className="card flex-fill">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <div className="mt-1">
                  <RetrainButton />
                </div>
              </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  placeholder="Search.."
                  value={searchText}
                  onChange={handleSearch}
                  style={{ marginBottom: "10px", padding: "5px", width: "100%" }}
                />
              </div>
            </div>

            {fetchedUrl && <div className="alert alert-info">Please select URLs to fetch the data.</div>}

            {loading ? (
              <p>Loading...</p>
            ) : documents.length === 0 ? (
              <p>No data available</p>
            ) : (
              <div>
                <DataTable
                  columns={columns}
                  data={filteredDocuments}
                  pagination
                  responsive
                  expandableRows
                  expandableRowsComponent={({ data }) => <ExpandedRow data={data} />}
                  expandOnRowClicked
                />
              </div>
            )}
            <br />
            <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>
            <br />
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Edit Images" style={EditModalStyle}>
        <EditTrainedData document={currentDocument} onClose={closeModal} onUpdate={fetchDocuments} />
      </Modal>
    </div>
  );
}