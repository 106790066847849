import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";

export default function AddHomePage({ closeModal, refresh, handleRefresh }) {

  useEffect(() => {
    document.title = 'Add Homepage | KlonIT Web Application';
  }, []);

  const [inputValue, setInputValue] = useState("");
  const [homepages, setHomepage] = useState([]);
  const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[a-z]{2,}([\/\w .-]*)*\/?$/i;
  const [error, setError] = useState(null);
  const [fetchingIcon, setFetchingIcon] = useState("");
  const [fetchingText, setFetchingText] = useState("");

  const handleAddWebBtnClick = async () => {
    if (urlRegex.test(inputValue)) {
      try {
        setFetchingIcon("fa fa-spinner fa-spin");
        setFetchingText("Keep calm, we are fetching...");
        setError(null); // Reset error before request
  
        const token = localStorage.getItem('access_token');
        const headers = {
          Authorization: `Bearer ${token}`
        };
  
        const response = await axios.post(`${backendURL}/add-sitemap`, { sitemap: inputValue }, { headers });
  
        setHomepage([...homepages, inputValue]);
        setInputValue("");
        setError("Homepage has been added successfully.");
        handleRefresh(); // Refresh the list after adding
        // closeModal(); // Close modal only if successful
      } catch (error) {
        // console.error("Error adding Homepage:", error);
        setError(error.response?.data?.message || "An error occurred while adding the Homepage.");
      } finally {
        setFetchingIcon("");
        setFetchingText("");
      }
    } else {
      setError("Please enter a valid Homepage URL.");
    }
  };  

  return (
    <div>
      <main className="content" style={{ minHeight: '100%' }}>
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle">
              <strong>Add </strong> Homepage
            </h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-2">
                    (Add your homepage url below. We will fetch most of the links automatically. Homepage url should be started with https:// or www)
                  </h5>
                  <div className="row">
                    <div className="col-12 col-lg-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-lg-12">
                      <button
                        className="btn btn-primary"
                        onClick={handleAddWebBtnClick}
                      >
                        Add Homepage
                      </button>
                      {fetchingIcon && (
                        <div className="mt-3">
                          <i className={fetchingIcon} aria-hidden="true"></i> {fetchingText}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-12">
                      <div id="getError" className={`alert alert-warning mt-3 ${error ? "" : "d-none"}`}>
                        {error}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}