import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import backendURL from "../../config";
import { Tooltip } from "@mui/material"; // Import Tooltip from MUI


export default function ListWebsites({ refresh, setRefresh }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fetchedUrl = queryParams.get('fetched_url');

  useEffect(() => {
    document.title = 'Website Lists | KlonIT Web Application';
    fetchDocuments();
  }, [refresh]);

  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectionError, setSelectionError] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [searchText, setSearchText] = useState(""); // State for search text

  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(`${backendURL}/get-unscrape-website-links`, { headers });

      setDocuments(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError(error.response.data.error);
      } else {
        setError(error.message);
      }
      setLoading(false);
    }
  };

  const getFileType = (websiteLink) => {
    const lowerCaseLink = websiteLink.toLowerCase();

    if (lowerCaseLink.endsWith('.pdf')) {
      return "PDF";
    } else if (lowerCaseLink.endsWith('.doc') || lowerCaseLink.endsWith('.docx')) {
      return "Document";
    } else if (lowerCaseLink.endsWith('.png') || lowerCaseLink.endsWith('.jpg') || lowerCaseLink.endsWith('.jpeg') || lowerCaseLink.endsWith('.gif')) {
      return "Image";
    } else if (lowerCaseLink.endsWith('.csv') || lowerCaseLink.endsWith('.xlsx') || lowerCaseLink.endsWith('.xls')) {
      return "Structure Document";
    } else if (lowerCaseLink.startsWith('http') || lowerCaseLink.startsWith('https') || lowerCaseLink.startsWith('www')) {
      return "URL";
    } else {
      return "Other";
    }
  };

  const getFileTypeColor = (fileType) => {
    switch (fileType) {
      case "PDF":
        return "#8B0000"; // Dark red
      case "URL":
        return "#006400"; // Dark green
      case "Document":
        return "#A52A2A"; // Brown
      case "Image":
        return "#556B2F"; // Olive green
      case "Structure Document":
        return "#808000"; // Olive
      default:
        return "#2F4F4F"; // Dark slate gray
    }
  };

  const handleRowSelected = (state) => {
    if (state.selectedRows.length <= 10) {
      setSelectedDocuments(state.selectedRows);
      setSelectionError(null);
    } else {
      setSelectionError("You can only select up to 10 rows.");
      setToggleCleared(!toggleCleared);
    }
  };

  const handleScrapeDataBTN = async () => {
    setFetching(true);
    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      const websiteLinks_id = selectedDocuments.map(doc => doc.id);
      const websiteLinks = selectedDocuments.map(doc => doc.website_link);

      const response = await axios.post(`${backendURL}/add-scrape-data`, { data: [websiteLinks_id, websiteLinks] }, { headers });

      setSelectedDocuments([]);
      setToggleCleared(!toggleCleared);
      setRefresh((prev) => !prev);
      setError(response.data.message); // Access the message correctly
    } catch (error) {
      setError(error.message); // Access the message directly from error
    } finally {
      setFetching(false);
    }
  };

  const handleDelete = async (documentId) => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      await axios.delete(`${backendURL}/delete-website-link/${documentId}`, { headers });
      setDocuments(documents.filter(doc => doc.id !== documentId));
      setError("Website link has been deleted successfully.");
    } catch (error) {
      setError(error.message);
    }
  };

    const handleView = (url) => {
        window.open(url, '_blank');
    };

  const columns = [
    {
      name: 'Website Link',
      selector: row => row.website_link,
      sortable: true,
      wrap: true
    },
    {
      name: 'File Type',
      selector: row => getFileType(row.website_link),
      sortable: true,
      width: '180px',
      cell: row => (
        <div style={{ backgroundColor: getFileTypeColor(getFileType(row.website_link)), color: 'white', padding: '5px', borderRadius: '3px' }}>
          {getFileType(row.website_link)}
        </div>
      ),
    },
    {
      name: 'Is Scrape?',
      selector: row => row.is_scrape ? "Yes" : "No",
      sortable: true,
      width: '150px',
    },
    {
      name: 'Create Date',
      selector: row => row.created_date,
      sortable: true,
      width: '150px',
    },
    {
      name: 'Action',
      cell: row => (
          <>          
            <Tooltip title="View Website Link">
                <button className="viewIcon" onClick={() => handleView(row.website_link)}>
                  <i className="fa fa-eye fa-2x"></i>
                </button>
            </Tooltip>
            <Tooltip title="Delete Website Link">
                <button className="deleteIcon" onClick={() => handleDelete(row.id)}>
                  <i className="fa fa-trash-o fa-2x"></i>
                </button>
            </Tooltip>
          </>
      ),
      width: '150px',  //Adjusted width to accomodate both the icons
    },
  ];

  const contextActions = (
    <div>
      <button onClick={handleScrapeDataBTN} className="btn btn-primary" disabled={fetching}>
        {fetching ? (
          <>
            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Fetching Data
          </>
        ) : (
          "Scrape Data and Train"
        )}
      </button>
    </div>
  );

  const handleChange = ({ selectedRows }) => {
    const limitedSelection = selectedRows.slice(0, 10);
    setSelectedDocuments(limitedSelection);
    if (selectedRows.length > 10) {
      setSelectionError("You can only select 10 files at a time to scrap.");
    } else {
      setSelectionError(null);
    }
  };

  const handleSelectAllChange = (state) => {
    const { selectedCount, selectedRows } = state;
    if (selectedCount > 0) {
      const limitedSelection = documents.slice(0, 10);
      setSelectedDocuments(limitedSelection);
      setSelectionError("You can only select 10 files at a time to scrap.");
    } else {
      setSelectedDocuments([]);
      setSelectionError(null);
    }
    setToggleCleared(!toggleCleared);
  };

  const ExpandedRow = ({ data }) => {
    return (
      <div style={{ padding: '10px', lineHeight: '1.5' }}>
        <p><strong>Website Link:</strong> {data.website_link}</p>
      </div>
    );
  };

  // Filter data based on search text
  const filteredDocuments = documents.filter((doc) => {
    const searchTextLower = searchText.toLowerCase();
    return (
      doc.website_link.toLowerCase().includes(searchTextLower) ||
      getFileType(doc.website_link).toLowerCase().includes(searchTextLower) ||
      (doc.is_scrape ? "Yes" : "No").toLowerCase().includes(searchTextLower) ||
      doc.created_date.toLowerCase().includes(searchTextLower)
    );
  });

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
        <div className="card flex-fill">
          <div className="card-header"></div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <div className="mt-1"></div>
              </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  placeholder="Search.."
                  style={{ marginBottom: "10px", padding: "5px", width: "100%" }}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </div>

            {fetchedUrl && (
              <div className="alert alert-info">
                Please select URLs to fetch the data.
              </div>
            )}

            {selectedDocuments.length > 0 && selectedDocuments.length <= 10 && (
              <div className="mt-3">
                <div className="alert alert-info">
                  <div className="row">
                    <div className="col-6">
                      {selectedDocuments.length} rows selected
                    </div>
                    <div className="col-6 text-end">
                      <button
                        onClick={handleScrapeDataBTN}
                        className="btn btn-primary"
                        disabled={fetching}
                      >
                        {fetching ? (
                          <>
                            Fetching Data{" "}
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          </>
                        ) : (
                          "Scrape Data and Train"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectionError && (
              <div className="alert alert-danger mt-3">{selectionError}</div>
            )}

            {loading ? (
              <p>Loading...</p>
            ) : filteredDocuments.length === 0 ? (
              <p>No data available</p>
            ) : (
              <div>
                <DataTable
                  columns={columns}
                  data={filteredDocuments}
                  pagination
                  selectableRows
                  selectableRowsHighlight
                  onSelectedRowsChange={handleChange}
                  onSelectAllRows={handleSelectAllChange}
                  clearSelectedRows={toggleCleared}
                  contextActions={contextActions}
                  expandableRows
                  expandableRowsComponent={({ data }) => (
                    <ExpandedRow data={data} />
                  )}
                  expandOnRowClicked
                />
              </div>
            )}
            <br />
            <div
              id="getError"
              className={`alert alert-warning ${error ? "" : "d-none"}`}
            >
              {error}
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}