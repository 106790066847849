import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  stripe_pricing_table_id, 
  stripe_publishable_key 
} from '../../config';

export default function ChatbotPricing() {
    const [isPricingTableAppended, setIsPricingTableAppended] = useState(false);
    const stripePricingTableRef = useRef(null);
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);

    const getUserIdFromToken = (token) => {
        if (!token) return null;
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            const payload = JSON.parse(jsonPayload);
            console.log("Token Payload:", payload);  // Log entire payload
            return payload.user_id || payload.sub || payload.userId;
        } catch (error) {
            console.error("Error decoding token:", error);
            return null;
        }
    };

    useEffect(() => {
        document.title = 'Pricing | KlonIT Web Application';

        const token = localStorage.getItem('access_token');
        const loadedUserId = getUserIdFromToken(token);
        
        console.log("Access Token:", token);
        console.log("Loaded User ID:", loadedUserId);

        if (!token || !loadedUserId) {
            navigate('/login');
            return;
        }

        setUserId(loadedUserId);

        const script = document.createElement('script');
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            if (stripePricingTableRef.current && !isPricingTableAppended && loadedUserId) {
                const stripePricingTable = document.createElement('stripe-pricing-table');
                stripePricingTable.setAttribute('pricing-table-id', stripe_pricing_table_id);
                stripePricingTable.setAttribute('publishable-key', stripe_publishable_key);
                
                // Explicitly set client reference ID
                stripePricingTable.setAttribute('client-reference-id', loadedUserId);
                
                // Also set in metadata for redundancy
                stripePricingTable.setAttribute('metadata', JSON.stringify({
                    user_id: loadedUserId
                }));

                stripePricingTableRef.current.appendChild(stripePricingTable);
                setIsPricingTableAppended(true);

                console.log("Stripe Pricing Table User ID:", loadedUserId);

                stripePricingTable.addEventListener('checkout-session-completed', (event) => {
                    console.log("Checkout session completed for user:", loadedUserId);
                    navigate('/payment-success');
                });
            }
        };

        return () => {
            if (script.parentNode) {
                document.body.removeChild(script);
            }
        };
    }, [isPricingTableAppended, navigate]);

    return (
        <div>
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="mb-3">
                        <h1 className="h3 d-inline align-middle"><strong>Our</strong> Pricing</h1>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title mb-0">Choose your pricing plan according to your requirements</h5>
                                    <br />
                                    <div ref={stripePricingTableRef}></div>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}