import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "react-select";
import axios from "axios";
import backendURL from "../../config";
import BillingOverview from './Overview';
import AddPayment from "./PaymentMethod/AddPayment";
import BillingPreferences from './Preferences';
import ChatbotPricing from './Pricing';
import InvoiceHistory from './InvoiceHistory';
import SubscriptionHistory from './SubscriptionHistory';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs() {

    useEffect(() => {
        document.title = 'Billing | KlonIT Web Application';
    }, []);

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                height: 224,
            }}
        >
            <div>
                <main className="content">
                    <div className="container-fluid p-0">
                        <div className="mb-3">
                            <h1 className="h3 d-inline align-middle">
                                <strong>User</strong> Billing
                            </h1>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-xl-2">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0 text-center">Billing</h5>
                                    </div>

                                    <div className="list-group list-group-flush" role="tablist">
                                        <Tabs
                                            orientation="vertical"
                                            variant="standard"
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                            sx={{ borderRight: 1, borderColor: "divider" }}
                                        >
                                            <Tab label="Overview" {...a11yProps(0)} />
                                            <Tab label="Billing History" {...a11yProps(1)} />
                                            <Tab label="Pricing" {...a11yProps(2)} />
                                            <Tab label="Subscription" {...a11yProps(3)} />
                                        </Tabs>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-9 col-xl-10">
                                <div className="tab-content">
                                    <TabPanel value={value} index={0}>
                                        <BillingOverview />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <InvoiceHistory />
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <ChatbotPricing />
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        <SubscriptionHistory />
                                    </TabPanel>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </Box>
    );
}
