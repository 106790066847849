import React, { useEffect, useState } from "react";
import axios from "axios";
import backendURL from "../../config";
import CreatePrompts from "../Professions/CreatePrompts";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";

export default function Prompt() {
  const [message, setMessage] = useState("");
  const [prompt, setPrompt] = useState("");
  const [greeting, setGreeting] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const token = localStorage.getItem("access_token");
  const headers = { Authorization: `Bearer ${token}` };

  // Fetch prompt data from the backend
  const fetchLLMSettings = async () => {
    try {
      const response = await axios.get(`${backendURL}/get-llm-settings`, {
        headers,
      });
      if (response.data) {
        setPrompt(response.data.prompt_text || "");
        setGreeting(response.data.greeting_text || "");
      }
    } catch (error) {
      setMessage("Error fetching LLM settings");
    }
  };

  // Refresh prompt data
  const refreshPromptData = async () => {
    await fetchLLMSettings(); // Re-fetch the settings
    setMessage("Prompt has been saved successfully!");
  };

  useEffect(() => {
    fetchLLMSettings(); // Initial data fetch
  }, []);

  const handleSave = async () => {
    setIsSaving(true);
    setIsSaved(false);
    setMessage(""); // Clear any previous messages

    try {
      const response = await axios.post(
        `${backendURL}/add-llm-settings`,
        {
          prompt_text: prompt,
          greeting_text: greeting,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setIsSaved(true);
        setMessage("LLM settings has been saved successfully!");
        setTimeout(() => {
            setIsSaved(false);
          }, 2000);
      } else {
        setMessage("Error saving LLM settings");
      }
    } catch (error) {
      setMessage("Error saving LLM settings");
    } finally {
      setIsSaving(false);
    }
  };

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  return (
    <div>
      <main className="content page-website-configurations">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-8 col-lg-8">
                      <div className="row">
                        <div className="col-6">
                          <h5 className="card-title mb-2">Prompt:</h5>
                        </div>
                        <div className="col-6" style={{ textAlign: "end" }}>
                          <a
                            id="GeneratePrompt"
                            className="mb-2"
                            onClick={toggleDrawer(true)}
                          >
                            Generate Prompt
                          </a>
                          <Tooltip
                            title="A prompt is a request or instruction given to an AI model to produce a specific response. The quality of the output is directly affected by the prompt."
                            arrow
                          >
                            <i className="fa fa-info-circle"></i>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="">
                        <textarea
                          id="prompting"
                          name="prompting"
                          className="form-control"
                          value={prompt}
                          onChange={(e) => setPrompt(e.target.value)}
                          style={{ height: "200px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-8 col-lg-8">
                      <h5 className="card-title mb-1">Greeting Text:</h5>
                      <div className="">
                        <textarea
                          id="greeting"
                          name="greeting"
                          className="form-control"
                          value={greeting}
                          onChange={(e) => setGreeting(e.target.value)}
                          style={{ height: "200px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-lg-12">
                      <button
                        className="btn btn-primary"
                        onClick={handleSave}
                        disabled={isSaving}
                      >
                        {isSaving ? (
                          <>
                            <i className="fa fa-spinner fa-spin"></i> Saving
                          </>
                        ) : isSaved ? (
                          <>
                            <i className="fa fa-check"></i> Saved
                          </>
                        ) : (
                          <>
                            <i className="fa fa-save"></i> Save
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-lg-12">
                      <div
                        id="getError"
                        className={`alert alert-info ${
                          message ? "" : "d-none"
                        }`}
                      >
                        {message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Box sx={{ width: 500, p: 2 }} role="presentation">
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <CreatePrompts onGeneratePrompt={refreshPromptData} />
        </Box>
      </Drawer>
    </div>
  );
}
