import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Select from "react-select";
import axios from "axios";
import backendURL from "../../config";
import CountrySelection from "./CountrySelection";

const ProfilePage = forwardRef((props, ref) => {
    useEffect(() => {
        document.title = 'Profile | KlonIT Web Application';
        fetchData();
    }, []);

    const [UserProfile, setUserProfile] = useState("");
    const [UserfName, setUserfName] = useState("");
    const [UserlName, setUserlName] = useState("");
    const [UserEmail, setUserEmail] = useState("");
    const [UserAddress, setUserAddress] = useState("");
    const [UserCountry, setUserCountry] = useState("");
    const [UserCity, setUserCity] = useState("");
    const [UserState, setUserState] = useState("");
    const [UserZip, setUserZip] = useState("");
    const [message, setMessage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);

    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`
    };

    const fetchData = () => {
        axios
            .get(`${backendURL}/get-user-profile`, { headers })
            .then((response) => {
                if (response.data.length > 0) {
                    const latestConfig = response.data[response.data.length - 1];
                    setUserProfile(latestConfig.profile_image);
                    setUserfName(latestConfig.fname);
                    setUserlName(latestConfig.lname);
                    setUserEmail(latestConfig.email);
                    setUserAddress(latestConfig.address_one);
                    setUserCountry(latestConfig.country);
                    setUserCity(latestConfig.city);
                    setUserState(latestConfig.state);
                    setUserZip(latestConfig.zip);
                }
            })
            .catch((error) => {
                setMessage("Error fetching for User Profile:", error);
            });
    };

    const handleCountryChange = (selectedOption) => {
        setUserCountry(selectedOption ? selectedOption.value : "");
    };

    const getDefaultCountryOption = () => {
        return CountrySelection.find(option => option.value === UserCountry);
    };

    const handleSaveButtonClick = async () => {
        if (selectedFile) {
            // Check if the selected file is an image
            const validExtensions = ['image/jpeg', 'image/jpg', 'image/png'];
            if (!validExtensions.includes(selectedFile.type)) {
                setMessage("Invalid file type. Only JPG, JPEG, and PNG are allowed.");
                return;
            }

            // Create a new FormData object to send the file to the server
            const formData = new FormData();
            formData.append('avatar', selectedFile);

            // Append additional data to the FormData object
            formData.append('fname', UserfName);
            formData.append('lname', UserlName);
            formData.append('address_one', UserAddress);
            formData.append('country', UserCountry);
            formData.append('city', UserCity);
            formData.append('state', UserState);
            formData.append('zip', UserZip);

            try {
                // Send the image and additional data to the server
                const response = await axios.post(`${backendURL}/update-user-profile`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                setMessage(response.data.message);
            } catch (error) {
                setMessage("Error updating user profile.");
                console.error(error);
            }
        } else {
            // If no file is selected, just update the user profile with the existing data
            const data = {
                fname: UserfName,
                lname: UserlName,
                address_one: UserAddress,
                country: UserCountry,
                city: UserCity,
                state: UserState,
                zip: UserZip,
                profile_image: UserProfile,
            };

            axios
                .post(`${backendURL}/update-user-profile`, data, { headers })
                .then((response) => {
                    setMessage(response.data.message);
                })
                .catch((error) => {
                    if (error.response) {
                        setMessage(error.response.data.message);
                    } else if (error.request) {
                        setMessage(error.request);
                    } else {
                        setMessage(error.message);
                    }
                });
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    useImperativeHandle(ref, () => ({
        handleSaveButtonClick,
    }));

    useEffect(() => {
        const isValid = UserfName !== "" &&
            UserlName !== "" &&
            UserEmail !== "" &&
            UserAddress !== "" &&
            UserCountry !== "" &&
            UserCity !== "" &&
            UserState !== "" &&
            UserZip !== "";
        setIsFormValid(isValid);
        props.onFormValidityChange(isValid); // Communicate to parent
    }, [UserfName, UserlName, UserEmail, UserAddress, UserCountry, UserCity, UserState, UserZip, props]);

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title mb-0">Help us personalize your experience. Tell us a little more about you.</h5>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="inputFirstName">
                                    First name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputFirstName"
                                    value={UserfName}
                                    onChange={(e) => setUserfName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="inputLastName">
                                    Last name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputLastName"
                                    value={UserlName}
                                    onChange={(e) => setUserlName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="inputEmail">
                                Email
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                id="inputEmail"
                                value={UserEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="inputAddress">
                                Address
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="inputAddress"
                                value={UserAddress}
                                onChange={(e) => setUserAddress(e.target.value)}
                            />
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label" htmlFor="inputCountry">
                                    Country
                                </label>
                                <Select
                                    id="inputCountry"
                                    options={CountrySelection}
                                    value={getDefaultCountryOption()}
                                    onChange={handleCountryChange}
                                    isClearable
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label" htmlFor="inputCity">
                                    City
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputCity"
                                    value={UserCity}
                                    onChange={(e) => setUserCity(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label" htmlFor="inputState">
                                    State
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputState"
                                    value={UserState}
                                    onChange={(e) => setUserState(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label" htmlFor="inputZip">
                                    Zip Code
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputZip"
                                    value={UserZip}
                                    onChange={(e) => setUserZip(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button className="btn btn-primary" onClick={handleSaveButtonClick}>
                    Save changes
                </button> */}
                <br />
                <br />
                <div id="getError" className={`alert alert-info ${message ? "" : "d-none"}`}>{message}</div>
            </div>
        </div>
    )
});

export default ProfilePage;