import React, { useState } from "react";
import axios from "axios";
import backendURL from "../../config";

export default function EditVoice({ document, onClose }) {
  const [pageContent, setPageContent] = useState(document?.content || "");
  const [allData, setAllData] = useState(document || "");
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false); // Track saving state
  const [isSaved, setIsSaved] = useState(false); // Track if save was successful

  const handleSave = async () => {
    setIsSaving(true); // Start saving
    setIsSaved(false); // Reset saved state
    setError(null); // Clear any previous errors

    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${backendURL}/update-voice`,
        { id: document.id, content: pageContent, all_data: allData },
        { headers }
      );

      if (response.status === 200) {
        console.log("Document updated successfully");
        setIsSaved(true); // Mark as saved successfully

        // Optional: Auto-close the modal after a short delay
        setTimeout(() => {
          onClose();
          setIsSaved(false); // Reset isSaved state for next time
        }, 2000); // Close after 2 seconds
      }
    } catch (error) {
      console.error("Error updating voice data:", error);
      setError("Failed to update the voice data");
    } finally {
      setIsSaving(false); // Stop saving (whether successful or not)
    }
  };

  return (
    <div>
      <h3>Edit Voice Data</h3>
      <textarea
        className="form-control"
        value={pageContent}
        onChange={(e) => setPageContent(e.target.value)}
        rows="10"
      />
      <br />
      <button
        className="btn btn-primary"
        onClick={handleSave}
        disabled={isSaving} // Disable while saving
      >
        {isSaving ? (
          <>
            <i className="fa fa-spinner fa-spin"></i> Saving
          </>
        ) : isSaved ? (
          <>
            <i className="fa fa-check"></i> Saved
          </>
        ) : (
          <>
            <i className="fa fa-save"></i> Save
          </>
        )}
      </button>
      <button
        className="btn btn-secondary mx-2"
        onClick={onClose}
        disabled={isSaving} // Disable while saving
      >
        <i className="fa fa-remove"></i> Cancel
      </button>
      {error && <div className="alert alert-danger mt-3">{error}</div>}
    </div>
  );
}
