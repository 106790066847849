import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import backendURL from '../config';

export default function ResetPasswordPage() {
    const [newPassword, setNewPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { token } = useParams(); // Get the token from the URL
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Set New Password | KlonIT Web Application';
        // You could add a check here to see if the token is valid *before* showing
        // the form, by making a request to a backend endpoint that validates the token.
    }, [token]);  // Run effect when the token changes

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage('');
        setError('');
        setLoading(true);

        if (newPassword !== verifyPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }
        if(newPassword.length < 8){
            setError("New password must be at least 8 characters long");
            setLoading(false)
            return;
        }

        try {
            const response = await fetch(`${backendURL}/reset-password/${token}`, { // New endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newPassword }), // Send only the new password
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(data.message);
                // Redirect to the login page after a successful password reset
                navigate('/Login'); // Use navigate for redirection
            } else {
                setError(data.message || 'An error occurred.');
            }
        } catch (err) {
            setError('Network error. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <main className="d-flex w-100 h-100">
                <div className="container d-flex flex-column">
                    <div className="row vh-100">
                        <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">
                                <div className="text-center mt-4">
                                    <h1 className="h2">Set New Password</h1>
                                    <p className="lead">
                                        Enter your new password below.
                                    </p>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="m-sm-3">
                                            <form onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label className="form-label">New Password</label>
                                                    <input
                                                        className="form-control form-control-lg"
                                                        type="password"
                                                        value={newPassword}
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Verify Password</label>
                                                    <input
                                                        className="form-control form-control-lg"
                                                        type="password"
                                                        value={verifyPassword}
                                                        onChange={(e) => setVerifyPassword(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                 <div className="d-grid gap-2 mt-3">
                                                    <button type="submit" className="btn btn-lg btn-primary" disabled={loading}>
                                                        {loading ? "Changing..." : "Change password"}
                                                    </button>
                                                </div>
                                                {message && <div className="alert alert-success">{message}</div>}
                                                {error && <div className="alert alert-danger">{error}</div>}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}