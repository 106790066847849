import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, } from "react-router-dom";

import Layout from './components/Layout';
import Sidebar from './components/Sidebar';
import './App.css';
import './assets/css/bootstrap.min.css';
import './assets/css/app.css';
import './assets/css/custom.css';

import Dashboard from './pages/RetrainChatbot';
import AddDocuments from './pages/Documents/AddDocuments';
// import AddWebsite from './pages/WebsiteLinks/AddWebsite';
import Scraper from './pages/WebsiteLinks/Scraper.js';
import WebsiteConfigurations from './pages/WebsiteConfigurations';
import AudioLanguage from './pages/AudioLanguage/AddAudioLanguage';
// import AddLanguages from './pages/AudioLanguage/AddLanguages.js';
import ListAudioLanguage from './pages/AudioLanguage/ListAudioLanguage.js';
import ChatHistory from './pages/ChatHistory';
import ChoosePosition from './pages/ChoosePosition';
import AvatarSettings from './pages/AvatarSettings';
import Analytics from './pages/Analytics';
import UserProfile from './pages/UserProfile';
import Test from './pages/Test';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword.js';
import ColorSettings from './pages/ColorSettings';
import ImproveQuestions from './pages/QuestionImprovement/AddImproveQuestions';
import ImproveAnswer from './pages/AnswerImprovement/ImproveAnswer';
import LogoutPage from './pages/LogoutPage';
import Pronoun from './pages/Pronoun/AddPronoun';
import AddSitemap from './pages/Sitemaps/AddSitemap';
import ListUserFeedback from './pages/UserFeedback.js';
import Help from './info-pages/Help.js';
import Support from './info-pages/Support.js';
import Privacy from './info-pages/Privacy.js';
import Terms from './info-pages/Terms.js';
import Billing from './pages/Billing/Billing.js';
import InvoiceHistory from './pages/Billing/InvoiceHistory.js';
import Pricing from './pages/Billing/Pricing.js';
import SubscriptionHistory from './pages/Billing/SubscriptionHistory.js';
import RetrainChatbot from './pages/RetrainChatbot.js';
import LiveChatbot from './pages/Chatbot/Live.js';
import LLMSettings from './pages/LLMSettings.js';
import ChatbotSettings from './pages/ChatbotSettings/ChatbotSettings.js';
import Prompt from './pages/ChatbotSettings/Prompt.js';
import AddImage from './pages/Image/AddImage.js';
import ListMonetize from './pages/Monetize/ListMonetize.js';
import VoiceTranscription from './pages/VoiceRecording/Recording.js';
import KnowledgeData from './pages/Knowledge/Knowledge.js';
import VerifiedEmail from './pages/Verify.js';
import SignUpSteps from './pages/Steps/SignUpSteps.js';
import PaymentSuccess from './pages/Billing/PaymentSuccess.js';
import ResetPasswordPage from './pages/ResetPasswordPage.js';

function App() {
    const isLoggedIn = localStorage.getItem('access_token');
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleToggleSidebar = () => {
        setIsCollapsed(!isCollapsed)
    };

    return (
        <BrowserRouter>
            <Routes>
                {/* Routes outside Layout */}
                <Route path="/SignUp-Process-Steps" element={<SignUpSteps />} />
                <Route path="/Login" exact element={<SignIn />} />
                <Route path="/SignUp" exact element={<SignUp />} />
                <Route path="/forgot-password" exact element={<ForgotPassword />} />
                 <Route path="/log-out" exact element={<LogoutPage />} />
                <Route path="/live" exact element={<LiveChatbot />} />
                <Route path="/verify/:token" exact element={<VerifiedEmail />} />
                <Route path="/reset-password/:token" element={<ResetPasswordPage />} />


                {/* Routes inside Layout */}
                <Route path="/*" element={
                  isLoggedIn ? (
                    <Layout isCollapsed={isCollapsed} onToggleSidebar={handleToggleSidebar}>
                      <Routes>
                          <Route path="/" element={<Dashboard />} />
                          <Route path="/Dashboard" element={<Dashboard />} />
                          <Route path="/add-documents" element={<AddDocuments />} />
                          <Route path="/add-image" element={<AddImage />} />
                          <Route path="/add-website" element={<Scraper />} />
                          <Route path="/configuration" exact element={<WebsiteConfigurations />} />
                          <Route path="/add-sitemap" element={<AddSitemap />} />
                          <Route path="/audio" element={<ListAudioLanguage />} />
                          <Route path="/chat-history" element={<ChatHistory />} />
                          <Route path="/choose-position" element={<ChoosePosition />} />
                          <Route path="/avatar" element={<AvatarSettings />} />
                          <Route path="/analytics" element={<Analytics />} />
                          <Route path="/user-profile" element={<UserProfile />} />
                         <Route path="/Test" element={<Test />} />
                          <Route path="/thumbnail-color" element={<ColorSettings />} />
                          <Route path="/question-improvements" element={<ImproveQuestions />} />
                          <Route path="/answers-improvements" element={<ImproveAnswer />} />
                         <Route path="/Pronoun" element={<Pronoun />} />
                         <Route path="/user-feedback" element={<ListUserFeedback />} />
                          <Route path="/help" element={<Help />} />
                         <Route path="/support" element={<Support />} />
                         <Route path="/privacy" element={<Privacy />} />
                         <Route path="/terms" element={<Terms />} />
                         <Route path="/Billing" element={<Billing />} />
                         <Route path="/Billing/Billing-History" element={<InvoiceHistory />} />
                         <Route path="/Billing/Pricing" element={<Pricing />} />
                         <Route path="/Billing/Subscription" element={<SubscriptionHistory />} />
                         <Route path="/RetrainChatbot" element={<RetrainChatbot />} />
                         <Route path="/ChatbotSettings" element={<ChatbotSettings />} />
                         <Route path="/add-monetize" element={<ListMonetize />} />
                         <Route path="/Voice" element={<VoiceTranscription />} />
                        <Route path="/Knowledge" element={<KnowledgeData />} />
                        <Route path="/payment-success" element={<PaymentSuccess />} />
                     </Routes>
                   </Layout>
                    ) : (
                         <SignIn />
                      )
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;