const ENV = process.env.REACT_APP_ENV;

let backendURL;
let ChatScriptsURL;
let APPLICATION_URL;

if (ENV === "Development") {
    backendURL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
    ChatScriptsURL = process.env.REACT_APP_CHAT_SCRIPTS_URL_DEVELOPMENT;
    APPLICATION_URL = process.env.REACT_APP_APPLICATION_URL_DEVELOPMENT;
} else if (ENV === "Production") {
    backendURL = process.env.REACT_APP_BACKEND_URL_PRODUCTION;
    ChatScriptsURL = process.env.REACT_APP_CHAT_SCRIPTS_URL_PRODUCTION;
    APPLICATION_URL = process.env.REACT_APP_APPLICATION_URL_PRODUCTION;
}

// Google API
const google_login_api = "466151718035-jofm6ggf2c637pvnu3h50ajsmkpl4as9.apps.googleusercontent.com";

// Stripe testing
const stripe_pricing_table_id = "prctbl_1Pcp9mRrwklCUJlQJ99lUy2F";
const stripe_publishable_key = "pk_test_51PaeuvRrwklCUJlQ0UwHfE8pUPB536BjUTF1Hu4cyT1cMTRwSSvKJX5En7mBgi9B33YXVEkKnnhZx8cRsowxDTe200NmRiY9HM";


// Stripe
// const stripe_pricing_table_id = "prctbl_1PcnabRrwklCUJlQpES2yncc";
// const stripe_publishable_key = "pk_live_51PaeuvRrwklCUJlQSr6hR5Vh8GVKce1lV16BVT3NuLQMd3BO1kYAmEHFSjfDC1Z7sYQdr1Y64H4Cv2Sc8kLWmKHe00sS8GIMFW";


export default backendURL;
export { google_login_api, ChatScriptsURL, APPLICATION_URL, stripe_pricing_table_id, stripe_publishable_key };
