import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import backendURL from "../../config";
import ProfilePage from "../UserProfile/Profile";
import CreatePrompts from "../Professions/CreatePrompts";
import { Stepper, Step, StepLabel, Box } from "@mui/material";
import { Paper, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Navigate } from 'react-router-dom';
import ZeroPrice from "../Billing/ZeroPrice"; // Assuming this is your ChatbotPricing component

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    margin: '100px auto',
    maxWidth: '50%',
}));

export default function SignUpSteps() {
    useEffect(() => {
        document.title = 'Signup Steps | KlonIT Web Application';
    }, []);

    const [error, setError] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [profileFormValid, setProfileFormValid] = useState(false);
    const [createPromptsFormValid, setCreatePromptsFormValid] = useState(false); // Track CreatePrompts validity
    const [redirectToDashboard, setRedirectToDashboard] = useState(false);

    const profilePageRef = useRef(null);
    const createPromptsRef = useRef(null);

    const steps = ['Profile Settings', 'Create Prompts', 'Chatbot Pricing'];

    const handleNext = async () => {
        if (activeStep === 0) {
            if (profilePageRef.current) {
                profilePageRef.current.handleSaveButtonClick();
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 1) {
            if (createPromptsRef.current) {
                try {
                    // Await the result of handleGeneratePrompt
                    const isValid = await createPromptsRef.current.handleGeneratePrompt(); 
                    setCreatePromptsFormValid(isValid); // Update based on returned value
                    if (isValid) {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                } catch (error) {
                    console.error("Error in handleGeneratePrompt:", error);
                    // Optionally set an error state to show a message to the user
                }
            }
        } else {
            setRedirectToDashboard(true);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleProfileFormValidity = (isValid) => {
        setProfileFormValid(isValid);
    };

    // Add a callback for CreatePrompts validity
    const handleCreatePromptsFormValidity = (isValid) => {
        setCreatePromptsFormValid(isValid);
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <ProfilePage ref={profilePageRef} onFormValidityChange={handleProfileFormValidity} />;
            case 1:
                return <CreatePrompts ref={createPromptsRef} onFormValidityChange={handleCreatePromptsFormValidity} />;
            case 2:
                return <ZeroPrice />;
            default:
                return <Typography>Unknown Step</Typography>;
        }
    };

    if (redirectToDashboard) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <div>
            <main className="content2">
                <div className="container-fluid p-0">
                    <StyledPaper elevation={3}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Box mt={2}>
                            {getStepContent(activeStep)}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between' }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                disabled={
                                    (activeStep === 0 && !profileFormValid) ||
                                    (activeStep === 1 && !createPromptsFormValid)
                                }
                            >
                                {activeStep === steps.length - 1 ? "Finish" : "Next"}
                            </Button>
                            {activeStep === steps.length && (
                                <Button onClick={handleReset}>Reset</Button>
                            )}
                        </Box>
                    </StyledPaper>
                </div>
            </main>
        </div>
    );
}