import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";

export default function EditTrainedData({ document, onClose, onUpdate }) {
  const [pageContent, setPageContent] = useState(document?.page_content || "");
  const [allData, setAllData] = useState(document || "");
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [matchIndexes, setMatchIndexes] = useState([]);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);

  const textareaRef = useRef(null);
  const inputRef = useRef(null);

  // Handle saving to backend
  const handleSave = async () => {
    setIsSaving(true);
    setIsSaved(false);
    setError(null);

    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${backendURL}/update-trained-data`,
        { id: document.id, page_content: pageContent, all_data: allData },
        { headers }
      );

      if (response.status === 200) {
        console.log("Data updated successfully");
        setIsSaved(true);
        onUpdate();
      }
    } catch (error) {
      console.error("Error updating data:", error);
      setError("Failed to update the data");
    } finally {
      setIsSaving(false);
    }
  };

  // Handle search input change
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchText(query);

    if (!query) {
      setMatchIndexes([]);
      setCurrentMatchIndex(0);
      if (textareaRef.current) {
        textareaRef.current.setSelectionRange(0, 0); // Clear selection
      }
      return;
    }

    const text = textareaRef.current.value;
    const regex = new RegExp(`(${escapeRegExp(query)})`, "gi"); // Escape for regex safety
    let matchPositions = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
      matchPositions.push(match.index);
    }

    setMatchIndexes(matchPositions);
    setCurrentMatchIndex(0);  // Reset to the first match
    if(matchPositions.length > 0){
        focusOnMatch(matchPositions[0]); // Focus on the first match immediately
    } else if(textareaRef.current){
        textareaRef.current.setSelectionRange(0,0);
    }
  };


  // Navigate to next match
  const goToNextMatch = () => {
     if (matchIndexes.length === 0) return;
        const nextIndex = (currentMatchIndex + 1) % matchIndexes.length;
        setCurrentMatchIndex(nextIndex);
        focusOnMatch(matchIndexes[nextIndex]);
  };

  // Navigate to previous match
  const goToPrevMatch = () => {
     if (matchIndexes.length === 0) return;
        const prevIndex = (currentMatchIndex - 1 + matchIndexes.length) % matchIndexes.length;
        setCurrentMatchIndex(prevIndex);
        focusOnMatch(matchIndexes[prevIndex]);
  };

  // Focus on match in textarea and scroll
    const focusOnMatch = (index) => {
        if (textareaRef.current) {
            textareaRef.current.setSelectionRange(index, index + searchText.length);

            // Calculate and set scrollTop for scrolling
            const lineHeight = parseInt(window.getComputedStyle(textareaRef.current).lineHeight, 10);
            const linesAbove = textareaRef.current.value.substring(0, index).split('\n').length - 1;
            const scrollTop = linesAbove * lineHeight;
            textareaRef.current.scrollTop = scrollTop;
        }
    };

  // Utility to escape special regex characters
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  useEffect(() => {
    if (searchText && matchIndexes.length > 0) {
        focusOnMatch(matchIndexes[currentMatchIndex]);
    }
  }, [matchIndexes, currentMatchIndex, searchText]); // Add dependencies


    return (
        <div>
          <div className="row">
            <div className="col-6 col-lg-6">
              <h3>Edit Website Data</h3>
            </div>
            <div className="col-6 col-lg-6">
              {/* Search Input and Navigation */}
            <div className="d-flex align-items-center mb-2">
                <input
                    ref={inputRef}
                    type="text"
                    className="form-control me-2"
                    placeholder="Find text..."
                    value={searchText}
                    onChange={handleSearch}
                />
                <div className="d-flex flex-column">
                    <button
                        className="btn btn-outline-secondary px-2 py-0" // Reduced padding
                        onClick={goToPrevMatch}
                        disabled={matchIndexes.length <= 1}
                        title="Previous"
                        style={{ lineHeight: 1 }} // Added line-height
                    >
                        <i className="fa fa-chevron-up fa-sm"></i> {/* Smaller icon */}
                    </button>
                    <button
                        className="btn btn-outline-secondary px-2 py-0" // Reduced padding
                        onClick={goToNextMatch}
                        disabled={matchIndexes.length <= 1}
                        title="Next"
                        style={{ lineHeight: 1 }} // Added line-height
                    >
                        <i className="fa fa-chevron-down fa-sm"></i> {/* Smaller icon */}
                    </button>
                </div>
                {matchIndexes.length > 0 && (
                    <span className="ms-2 small">
                        {currentMatchIndex + 1} / {matchIndexes.length}
                    </span>
                )}
            </div>
            </div>
          </div>
            

            

            {/* Textarea */}
            <textarea
                ref={textareaRef}
                className="form-control"
                value={pageContent}
                onChange={(e) => {
                    setPageContent(e.target.value);
                }}
                rows="10"
            />
            <br />
            <button className="btn btn-primary" onClick={handleSave} disabled={isSaving}>
                {isSaving ? (
                    <>
                        <i className="fa fa-spinner fa-spin"></i> Saving
                    </>
                ) : isSaved ? (
                    <>
                        <i className="fa fa-check"></i> Saved
                    </>
                ) : (
                    <>
                        <i className="fa fa-save"></i> Save
                    </>
                )}
            </button>
            <button className="btn btn-secondary mx-2" onClick={onClose} disabled={isSaving}>
                <i className="fa fa-remove"></i> Cancel
            </button>
            {error && <div className="alert alert-danger mt-3">{error}</div>}
        </div>
    );
}